@import '@mozaic-ds/styles/settings-tools/all-settings';

@mixin set-sponsored() {
  user-select: none;
  cursor: pointer;
  @include set-font-scale('02', 'm');
  opacity: 70%;
  transition: all .3s;
  display: flex;
  align-items: center;
  color: $color-grey-999;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    display: block;
    background-color: transparent;
    height: $mu100;
    width: $mu100;
    border-radius: 100%;
    margin-left: $mu025;
    transition: all .3s;
  }
}
