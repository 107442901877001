$mozaic-env: development;
// Import of file containing all variables
@import '@mozaic-ds/styles/settings-tools/all-settings';
@import './common/sponsored';

#component-rtlmedia-homepage {
  position: relative;

  .banner-sponsored {
    align-self: baseline;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;

    @include set-sponsored();
    top:0;
    right: $mu150;
    left: 0;
    padding: 0 $mu050;
    margin-bottom: 8px;

    .icon {
      fill: white;
    }
  }

  .arm-homepage-open-modal {
    z-index: 1;
    text-decoration: none;
  }

  .banner {
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;

    .banner-content {
      justify-content: space-between;
      padding-top: 20px
    }

    .banner-content-button {
      margin-bottom: 10px;
    }

    &-picture {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &-background {
      display: block;
      width: 100%;
      height: auto;
      min-height: $mu1000;
      object-fit: cover;
      object-position: center center;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $mu050 $mu150;
      background-color: $color-grey-000;
      border-radius: $mu025;
      position: relative;
      z-index: 1;
      margin: -$mu200 auto 0 auto;
      width: 95%;

      &-picture {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: $mu400;
      }

      &-description {
        color: $color-grey-999;
        font-weight: 600;
        text-align: center;
        font-size: $size-font-05;
        line-height: $size-line-05-m;
        margin: $mu050 0;
      }

      &-button {
        background-color: $color-grey-800;
        color: $color-grey-000;
        border-radius: $mu025;
        min-width: $mu800;
        cursor: pointer;
        font-weight: 600;
        padding: $mu050 $mu100;
        font-size: $size-font-04;
        line-height: $size-line-04-m;
        text-align: center;

        &-text {
          mix-blend-mode: difference;
        }

      }
    }

    @media screen and (min-width: 680px) {
      &-sponsored {
        right: $mu250;
      }
      &-background {
        height: 100%;
        min-height: auto;
        object-fit: cover;
      }
      &-picture {
        position: initial;
        width: 100%;
      }
      &-content {
        min-height: 206px;
        width: 30%;
        min-width: 292px;
        margin: 0;
        border-radius: 0;
        order: 1;
      }
    }

    @media screen and (min-width: 1024px) {
      &-content {
        min-width: 288px;
      }
    }

    @media screen and (min-width: 1280px) {
      &-content {
        padding: $mu050 $mu200;
        min-width: 384px;
      }
    }
  }

  @media screen and (min-width: 680px) {
    .banner {
      position: initial;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 680px) {
  #component-rtlmedia-homepage {
    padding: 0;
  }
}
